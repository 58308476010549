import React, { useState } from "react";  
import { Flex, SearchField } from "gestalt";  
  
const Search = (props) => {  
  const [value, setValue] = useState("");  
  
  const onKeyDownManagement = ({ keyCode }) => {  
    props.innerOnSearchFieldSubmit(value);  
  };  
  
  return (  
    <Flex alignItems="center" flex="grow">  
      <Flex.Item flex="grow">  
        <SearchField  
          accessibilityLabel="?"  
          accessibilityClearButtonLabel="Clear search field"  
          label="Search Jobs"  
          id="searchjobs"  
          onChange={({ value }) => setValue(value)}  
          onKeyDown={onKeyDownManagement}  
          placeholder="Search by name"  
          value={value}  
        />  
      </Flex.Item>  
    </Flex>  
  );  
};  
export default Search;  