import Header from './components/Header'
import Home from './pages/Home'
import Applicants from './pages/Applicants'
import JobPromptInput from './pages/JobPromptInput'
import Feedback from './pages/Feedback'
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import JobPost from './pages/JobPost'

export default function Router() {
    const Layout = () => {
        return (
            <>
            <div className="content"><Outlet /></div>
            </>
        )
    }

    const BrowserRoutes = () => {
        return (
            <BrowserRouter>
                <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="applicants" element={<Applicants />} />
                    <Route path="applicants/:id" element={<Applicants />} />
                    <Route path="jobpost" element={<JobPost />} />
                    <Route path="jobpost-internal" element={<JobPromptInput />} />
                    <Route path="feedback/:id" element={<Feedback />} />
                </Route>
                </Routes>
            </BrowserRouter>
        )
    }
    return (
        <BrowserRoutes />
    )
}