import React from "react";
import { Link, Text } from "gestalt";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-material.css';

export const ApplicantListTable = (props) => {
  const data = props.data;
  const columnDefs = [
    {
      headerName: "Applicant Name",
      valueGetter: (params) => `${params.data["userFirstName"]} ${params.data["userLastName"]}`,
      resizable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        const feedbackId = encodeURIComponent(params.data["feedbackId"]);
        const userFirstName = encodeURIComponent(params.data["userFirstName"]);
        const userLastName = encodeURIComponent(params.data["userLastName"]);
        const url = `/feedback/${feedbackId}?name=${userFirstName}`;
        return (
          <Link href={url}>
            <Text weight="bold">{params.value}</Text>
          </Link>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      filter: "agTextColumnFilter",
    },
  ];
  return (
      <div className="ag-theme-material" style={{ height: 800 }}>
        <AgGridReact
          columnDefs={columnDefs}
          className="ag-theme-material"
          rowData={data}
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          overlayNoRowsTemplate={"Applicants Not found"}
          onFirstDataRendered={(g) => {
            g.api.applyColumnState({
              state: [
                {
                  colId: "userFirstName",
                  sort: "asc",
                },
              ],
            });
            g.api.sizeColumnsToFit();
          }}
        />
      </div>
  );
};