import axios from "axios";
import { Box, Button, FixedZIndex } from "gestalt";
import React, { useState, useEffect } from "react";
import { SideButton } from "../components/JobDescriptions/SideButton";
import { JobDescription } from "../components/JobDescriptions/JobDescription";
import { AdditionalInformation } from "../components/JobDescriptions/AdditionalInformation";
import { QuestionList } from "../components/JobDescriptions/QuestionList";
import { EvaluationCriterion } from "../components/JobDescriptions/EvaluationCriterion";
import { Review } from "../components/JobDescriptions/Review";
import { getUser } from "../components/getUser";
import { InvitationPage } from "../components/JobDescriptions/InvitationPage";
import { Header } from "../components/Header";
import { useUser } from "@clerk/clerk-react";

const App = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [jobTitle, setJobTitle] = useState("");
  const [jobID, setJobID] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [interviewQuestions, setInterviewQuestions] = useState([]);
  const [evaluationCriterion, setEvaluationCriterion] = useState([]);
  const [userData, setUserData] = useState(null);
  const [jobInterviewLink, setJobInterviewLink] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [interviewRound, setInterviewRound] = useState({
    value: "",
    label: "",
  });
  const b2cUrl = "mock-interview.mirwork.ai" + "/home?job=";
  const stepComponents = [
    <JobDescription
      jobTitle={jobTitle}
      setJobTitle={setJobTitle}
      jobDescription={jobDescription}
      setJobDescription={setJobDescription}
      experienceLevel={experienceLevel}
      setExperienceLevel={setExperienceLevel}
      interviewRound={interviewRound}
      setInterviewRound={setInterviewRound}
    />,
    <AdditionalInformation
      additionalInformation={additionalInformation}
      setAdditionalInformation={setAdditionalInformation}
    />,
    <QuestionList
      interviewQuestions={interviewQuestions}
      setInterviewQuestions={setInterviewQuestions}
    />,
    <EvaluationCriterion
      evaluationCriterion={evaluationCriterion}
      setEvaluationCriterion={setEvaluationCriterion}
    />,
    <Review
      jobTitle={jobTitle}
      jobDescription={jobDescription}
      experienceLevel={experienceLevel}
      additionalInformation={additionalInformation}
      interviewQuestions={interviewQuestions}
      evaluationCriterion={evaluationCriterion}
      interviewRound={interviewRound}
    />,

    <InvitationPage jobInterviewLink={jobInterviewLink} />,
  ];
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoaded) {
        let userInfo = await getUser(isSignedIn, user);
        setUserData(userInfo);
      }
    };
    fetchUserData();
  }, [isSignedIn, user, isLoaded]); // Empty dependency array means this effect runs once after the initial render

  const handleSubmit = async () => {
    const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/bData/jobCreation";
    const formatInterviewQuestions = interviewQuestions.map((q) => q.content);
    try {
      const response = await axios.post(apiUrl, {
        jobTitle: jobTitle,
        jobDescription: jobDescription,
        experienceLevel: experienceLevel,
        additionalInformation: additionalInformation,
        interviewQuestions: formatInterviewQuestions,
        evaluationCriterion: evaluationCriterion,
        interviewRound: interviewRound.value,
        recruiterID: userData.id,
        b2cUrl: b2cUrl,
      });

      console.log("Job created with ID:", response.data.data.jobID);
      setJobID(response.data.data.jobID); // Assuming the API returns a jobID
      setJobInterviewLink(
        // Original: b2cUrl + response.data.data.jobID + "?round=" + interviewRound.value
        b2cUrl + response.data.data.jobID
      );
      handleNextStep();
    } catch (error) {
      console.error("Error submitting job creation data:", error);
    }
  };
  const handleNextStep = () => {
    setCurrentStep((prevStep) => {
      const nextStep = Math.min(prevStep + 1, stepComponents.length - 1);
      // Only add to completedSteps if we're moving to a new step
      if (nextStep > prevStep && !completedSteps.includes(prevStep)) {
        setCompletedSteps([...completedSteps, prevStep]);
      }
      return nextStep;
    });
  };
  const handleBackStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    setCompletedSteps((prevCompleted) => {
      const newCompleted = [...prevCompleted];
      if (newCompleted.indexOf(currentStep) === -1) {
        newCompleted.push(currentStep);
      }
      return newCompleted;
    });
  }, [currentStep]);
  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box display="flex" direction="row" width="100%">
          {currentStep < stepComponents.length - 1 && (
            <Box width={280} height="100%">
              <SideButton
                onSelectStep={setCurrentStep}
                completedSteps={completedSteps}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            </Box>
          )}
          <Box display="flex" direction="column" width="100%" padding={3}>
            {stepComponents[currentStep]}
            {currentStep < stepComponents.length - 1 && (
              <Box
                display="flex"
                width="100%"
                justifyContent="around"
                alignItems="end"
                padding={5}
              >
                {currentStep > 0 && (
                  <Box width={100}>
                    <Button
                      color="gray"
                      size="lg"
                      text="Back"
                      fullWidth={true}
                      onClick={handleBackStep}
                    />
                  </Box>
                )}
                <Box width={100}>
                  <Button
                    color="blue"
                    size="lg"
                    fullWidth={true}
                    text={
                      currentStep === stepComponents.length - 2
                        ? "Submit"
                        : "Continue"
                    }
                    onClick={
                      currentStep === stepComponents.length - 2
                        ? handleSubmit
                        : handleNextStep
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default () => {
  return (
    <>
      <Header isAuthenticated={true} />
      <App />
    </>
  );
};
