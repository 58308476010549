import axios from "axios";

export async function getUser(isAuthenticated, user) {
  let userData = {
    email: "",
    firstName: "",
    lastName: "",
    avatar: "",
    id: null,
  };

  if (isAuthenticated) {
    let userData = {
      email: user.primaryEmailAddress.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      id: null,
    };
    console.log("Sign in with Email: " + user.primaryEmailAddress.emailAddress);
    const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
    try {
      let response = await axios.post(apiUrl, {
        email: user.primaryEmailAddress.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
      });
      userData.id = response.data.data.recruiterID;
      return userData;
    } catch (error) {
      console.error("Error saving message:", error);
    }
  }

  return userData;
}
