import React, { useState, useEffect } from "react"; 
import { Box, Button, Text, Flex, Heading } from 'gestalt';
import { eval_instr, criteria } from './JobDesConsts'

export const EvaluationCriterion = ({
    evaluationCriterion, setEvaluationCriterion
})  => {

    // Initialize activeCriteria from the evaluationCriterion passed from the parent
    const [activeCriteria, setActiveCriteria] = useState(
        criteria.reduce((acc, criterion) => ({
            ...acc,
            [criterion]: evaluationCriterion.includes(criterion)
        }), {})
    );

    // Effect to update state when evaluationCriterion changes externally
    useEffect(() => {
        setActiveCriteria(criteria.reduce((acc, criterion) => ({
            ...acc,
            [criterion]: evaluationCriterion.includes(criterion)
        }), {}));
    }, [evaluationCriterion]);
    const toggleCriterion = (criterion) => {
        // Toggle the active status of the criterion
        setActiveCriteria(prev => {
            const newActiveCriteria = { ...prev, [criterion]: !prev[criterion] };
            // Update the evaluationCriterion based on toggles
            const updatedCriterion = Object.keys(newActiveCriteria).filter(key => newActiveCriteria[key]);
            setEvaluationCriterion(updatedCriterion);
            return newActiveCriteria;
        });
    };
    

    return(
        <Box display="flex" direction="column" width="100%" padding={3}>
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>  
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" /> 
                <div style={{ marginRight: '10px' }}></div> 
                <Heading>Evaluation Criterion</Heading>
            </Flex>

            <Box display="flex" direction="column" alignItems="start" paddingY={5}>
                <Text>
                    {`${eval_instr}`}
                </Text>
            </Box>

            <Flex justifyContent="center" wrap>
                {criteria.map((criterion, index) => (
                <Box key={index} padding={2} width="33%" display="flex" justifyContent="center">
                    <Button
                    color={activeCriteria[criterion] ? 'red' : 'gray'}
                    text={criterion}
                    onClick={() => toggleCriterion(criterion)}
                    size="lg"
                    // Add a key to force the button to re-render when its state changes
                    key={activeCriteria[criterion] ? 'active' : 'inactive'}
                    />
                </Box>
                ))}
            </Flex>            
        </Box>
    )
}