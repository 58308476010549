import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Flex, Heading } from "gestalt";
import { getUser } from "../components/getUser";
import React, { useState, useEffect } from "react";
import { CenteredBody } from "../components/CenteredBody";
import Search from "../components/Applicants/Search";
import { ProjectGradeListTable } from "../components/Applicants/JobTable";
import { ApplicantListTable } from "../components/Applicants/ApplicantTable";
import { Header } from "../components/Header";
import { useUser } from "@clerk/clerk-react";

const App = () => {
  const [projects, setProjects] = useState([]);
  const [candidate, setCandidate] = useState([]);
  const [projectsDisplay, setProjectsDisplay] = useState([]);
  const params = useParams();
  const [userData, setUserData] = useState(null);
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    const fetchJobData = async () => {
      if (isLoaded) {
        let userInfo = await getUser(isSignedIn, user);
        setUserData(userInfo);
        const jobListRes = await axios.post(rootUrl + "/api/bData/jobList", {
          recruiterID: userInfo.id,
        });
        setProjects(jobListRes.data.data.jobs);
        setProjectsDisplay(jobListRes.data.data.jobs);
        console.log(jobListRes.data.data.jobs);
      }
    };

    const fetchApplicantData = async (id) => {
      const jobListRes = await axios.post(
        rootUrl + "/api/bData/applicantList",
        { jobID: id }
      );
      setCandidate(jobListRes.data.data.applicants);
    };

    if (params.id == undefined) {
      fetchJobData();
    } else {
      fetchApplicantData(params.id);
    }
  }, [isSignedIn, user, isLoaded]);

  const handleSubmit = (search) => {
    let display = projects;
    setProjectsDisplay(display.filter((item) => item.name.includes(search)));
  };

  const handleDelete = async (jobId) => {
    try {
      await axios.post(rootUrl + "/api/bData/jobDeletion", {
        jobId: jobId,
        userId: userData.id,
      });
      const updatedJobs = projects.filter((job) => job.jobId !== jobId);
      console.log("isfiltering");
      setProjects(updatedJobs);
      setProjectsDisplay(updatedJobs);
      console.log(projects);
    } catch (error) {
      console.error("Failed to delete job:", error);
    }
  };

  const outputContent =
    params.id == undefined ? (
      <ProjectGradeListTable data={projectsDisplay} onDelete={handleDelete} />
    ) : (
      <ApplicantListTable data={candidate} />
    );

  return (
    <>
      <Box
        marginTop={6}
        rounding={4}
        padding={4}
        borderStyle="none"
        width="100%"
      >
        <Flex justifyContent="start" alignItems="center" gap={2}>
          <img
            src="/icons/logo.png"
            width="40"
            height="40"
            alt="STK Logo"
            title="STK"
          />
          {params.id == undefined ? (
            <Heading>Job Application</Heading>
          ) : (
            <Heading> Applicants</Heading>
          )}
        </Flex>
        {params.id == undefined ? (
          <Box marginTop={6}>
            <Flex justifyContent="end" alignItems="end">
              <Search
                innerOnSearchFieldSubmit={handleSubmit}
                userSpecifiedProjectInput={""}
              />
            </Flex>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box
        alignSelf="center"
        padding={2}
        rounding={4}
        marginTop={6}
        borderStyle="shadow"
        width="100%"
        height="100%"
        display="flex"
        direction="column"
        flex="grow"
        overflow="hidden"
      >
        {outputContent ?? <></>}
      </Box>
    </>
  );
};

export default () => {
  return (
    <>
      <Header isAuthenticated={true} />
      <CenteredBody>
        <App />
      </CenteredBody>
    </>
  );
};
