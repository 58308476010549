import React, { useState} from "react";
import { Link, Text, Modal, Button, Box } from "gestalt";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-material.css';

export const ProjectGradeListTable = ({ data, onDelete}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const handleDelete = (jobId) => {
    setSelectedJobId(jobId);
    setShowModal(true);
  };

  const confirmDelete = () => {
    onDelete(selectedJobId);
    setShowModal(false);
  }

  const columnDefs = [
    {
      headerName: "Job Name",
      field: "title",
      resizable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        const url = `/applicants/${encodeURIComponent(params.data.jobId)}`;
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Link href={url}>
                <Text weight="bold">{params.value}</Text>
            </Link>
            <Button color="red" text="Delete" size="sm" onClick={() => handleDelete(params.data.jobId)} />
        </div>
        );
      },
    },
  ];
  return (
    <>
      <div className="ag-theme-material" style={{ height: 800 }}>
        <AgGridReact
          columnDefs={columnDefs}
          className="ag-theme-material"
          rowData={data}
          pagination={true}
          paginationPageSize={100}
          enableCellTextSelection={true}
          overlayNoRowsTemplate={"Project Not found"}
          onFirstDataRendered={(g) => {
            g.api.applyColumnState({
              state: [
                {
                  colId: "title",
                  sort: "asc",
                },
              ],
            });
            g.api.sizeColumnsToFit();
          }}
        />
      </div>
      {showModal && (
        <Modal
          accessibilityModalLabel="Confirm Delete Job"
          heading="Delete Job?"
          onDismiss={() => setShowModal(false)}
          footer={
            <Box display="flex" justifyContent="end" marginEnd={4}>
              <Box padding={2}>
                <Button text="Cancel" onClick={() => setShowModal(false)} inline />
              </Box>
              <Box padding={2}>
                <Button text="Delete" color="red" onClick={confirmDelete} inline />
              </Box>
            </Box>
          }
          size="sm"
        >
          <Box paddingX={4} paddingY={2}>
            <Text>This action cannot be undone.</Text>
          </Box>
        </Modal>
      )}
    </>
  );
};
