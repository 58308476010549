import React from "react"; 
import { Box, Text, Flex, Heading, TextArea, List } from 'gestalt';
import { add_info_instr, add_info_instr_cont, add_info_common_questions } from './JobDesConsts'

export const AdditionalInformation = ({
    additionalInformation, setAdditionalInformation
    }) => {
    return(
        <Box display="flex" direction="column" width="100%" padding={3} >
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>  
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" /> 
                <div style={{ marginRight: '10px' }}></div> 
                <Heading>Additional information</Heading>
            </Flex>

                <Box display="flex" direction="column" alignItems="start" paddingY={5}>
                    <Text>
                        {`${add_info_instr}`}
                    </Text>
                </Box>

                <Box display="flex" direction="column" alignItems="start" paddingY={1}>
                    <Text>
                        {`${add_info_instr_cont}`}
                    </Text>
                    <Box paddingY={5}>
                        <List>
                            {add_info_common_questions.map((q, idx) => (
                                <List.Item key={idx} text={q} />
                            ))}
                        </List>
                    </Box>
                </Box>
                
                <Box  alignItems="center" justifyContent="center"  width="100%" paddingX={5}>
                    <TextArea
                        id="additionalInformation"
                        onChange={({ value }) => setAdditionalInformation(value)}
                        placeholder="Please type more information you want to share"
                        value={additionalInformation}
                        size="lg"
                        rows={10}
                    />
                </Box>
            
        </Box>
    );
}