import axios from "axios";  
import { useParams, useLocation } from "react-router-dom"
import { Box, Flex, Heading, ButtonLink, Divider, Text, Accordion, Status } from "gestalt";  
import React, { useState, useEffect } from "react";  
import { Radar } from "../components/Feedback/Radar";
import { Header } from "../components/Header";

const App = () => {  
  const [feedback, setFeedback] = useState({ candidate: '', overview: '', content: [], chatHistory: [] });
  const [errorMsg, setErrorMsg] = useState("");
  const [radarData, setRadarData] = useState([]);
  const params = useParams()
  const query = useQuery();

  const rootUrl = process.env.REACT_APP_BACKEND_URL 

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
  useEffect(() => {  
    const fetchFeedbackData = async (id) => {  
        const feedbackRes = await axios.post(rootUrl + "/api/bData/applicantFeedback", {feedbackId: id});  
        setFeedback({candidate: query.get('name'), 
        overview: feedbackRes.data.data.feedbackOverview,
        content: feedbackRes.data.data.feedbackContent,
        chatHistory: feedbackRes.data.data.chatHistory,
        });
        console.log("Feedback set:", {
          candidate: query.get('name'),
          content: feedbackRes.data.data.feedbackContent,
          chatHistory: feedbackRes.data.data.chatHistory
        }); 
    }

    if (params.id == undefined){
        setErrorMsg("No Valid Feedback ID")
    } else {
        fetchFeedbackData(params.id);
    }
  }, []);  

  useEffect(() => {
    if (feedback.content.length > 0) {
      const newRadarData = feedback.content.map(section => ({
        "section": section.section,
        "score (0-5)": section.score,
      }));
      setRadarData(newRadarData);
    }
  }, [feedback]);

  const topSkills = feedback.content?.filter(item => item.score > 3) || [];
  const skillsToImprove = feedback.content?.filter(item => item.score <= 3) || [];

  if (!feedback) {
    return <div>{"Loading..."}</div>; 
  }
  
  const outputContent = ( 
      
    <Box
      alignItems="center"
      height="100%"
      width="100%"
      justifyContent="center"
      padding={8}
      marginTop = {6}
    >
        <Box marginTop={0} rounding={4} padding={4}>
          <Flex justifyContent="end" alignItems="center" gap={2}>
            <ButtonLink
              text="Back to List"
              size="md"
              href="/applicants"
            />
          </Flex>
          <Flex justifyContent="start" alignItems="center" gap={2}>
            <img src="/icons/logo.png" width="40" height="40" alt="STK Logo" title="STK" />
            <Heading>Feedback for {feedback.candidate}</Heading>
          </Flex>
          <Box marginTop={2} rounding={4} padding={2}>
              <Flex justifyContent="between" alignItems="center">
                  <Text size="100" color="subtle">
                      {feedback.overview}
                  </Text>
              </Flex>
          </Box>
        </Box>
        <Divider />
        
        <Box
        padding={8}
        height="100%"
        alignItems="center"
        justifyContent="center"
        >
          
            <Flex alignItems="stretch" justifyContent="between" width="100%" gap={2}>

                <Box 
                  marginTop={0}
                  padding={3}
                  height={550}
                >
                <Box
                  borderStyle="shadow"
                  marginTop={0}
                  rounding={4}
                  padding={6}
                >
                  <Box
                    marginTop={0}
                    rounding={4}
                    padding={0}
                  >
                      <Heading size="sm" align="center">
                        Overview Chart for { query.get('name') }'s interview
                      </Heading>
                    </Box>
                    <Radar radarData = {radarData}/>
                </Box>
                </Box>
                    <Box 
                        marginTop={0}
                        padding={3}
                        flex="1"
                        display="flex"
                        flexDirection="column"
                    >
                      <Accordion id="ModuleExample - default - 3" title="Product Sense Evaluation">
                        {topSkills.length > 0 && (
                        <>
                          <Text color='subtle'> Candidate's top skills </Text>
                          {topSkills.map((item, index) => (
                            <Box
                              padding={3}
                              height="100%"
                              display="flex"
                              alignItems="center"
                              justifyContent="start"
                              key={'top-' + index}
                            >
                              <Status type="ok" title={item.section} subtext={item.text} />
                            </Box>
                          ))}
                        </>
                        )}
                        {skillsToImprove.length > 0 && (
                         <>
                          <Text color='subtle'> Skills need to be improved </Text>
                            {skillsToImprove.map((item, index) => (
                              <Box
                                padding={3}
                                height="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="start"
                                key={'improve-' + index}
                              >
                                <Status type="problem" title={item.section} subtext={item.text} />
                              </Box>
                            ))}
                          </>
                        )}
                      </Accordion>
                    </Box>
            </Flex>
           
            <Box
              paddingY={6}
              paddingX={4}
              width="100%"
              borderStyle="shadow"
              rounding={4}
            >
              <Box
                marginBottom={4}
                rounding={4}
                paddingX={3}
              >
                <Heading align="center" size="md">Chat History</Heading>
              </Box>
                {feedback.chatHistory && feedback.chatHistory.map((chat, index) => (
                  <Box key={index} paddingY={2} paddingX={4} display="flex" direction="column" marginBottom={1}>
                    <Text size="lg">
                      {chat.role === "assistant" ? "Assistant:" : `${feedback.candidate}:`}
                    </Text>
                    <Text color='subtle' size="md" style={{ marginTop: '4px' }}>
                      {chat.content}
                    </Text>
                  </Box>
                ))}
            </Box>
        </Box>
    </Box>
  );  
  
  return (  
    <>  
      <Header isAuthenticated={true}/>
      {errorMsg===""?outputContent: (<Heading> {errorMsg} </Heading> )}
    </>  
  );  
};  
  
export default () => {  
  return (  
        <App />  
  );  
};  
