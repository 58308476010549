import React from 'react';
import { Box, Text, Link } from 'gestalt';
import 'gestalt/dist/gestalt.css';

export const Header = (props) => {
    if (props.isAuthenticated) {
        return (
            // <nav className="nav-bar">
            // <ul>
            //     <li><a href="/">Home</a></li>
            //     <li><a href="/applicants">Applicants</a></li>
            //     <li><a href="/jobpost">Job Post</a></li>
            // </ul>
            // </nav>
            <Box color="dark" paddingY={3} paddingX={4} display="flex" alignItems="center" justifyContent="start">
                <Link href="/" inline>
                    <Text color="light" weight="bold">Home</Text>
                </Link>
                <Box paddingX={2}> {/* Adjust the space between links as needed */}
                <Link href="/applicants" inline>
                    <Text color="light" weight="bold">Applicants</Text>
                </Link>
                </Box>
                <Link href="/jobpost" inline>
                    <Text color="light" weight="bold">Job Post</Text>
                </Link>
            </Box>
        )
    }
    return (
        <Box color="dark" paddingY={3} paddingX={4} display="flex" alignItems="center" justifyContent="start">
            <Link href="/" inline>
                <Text color="light" weight="bold">Home</Text>
            </Link>
        </Box>
    )
}

//To do: Now the header is not sensitive to the change of user/isAuth, uncomment here
//       when the user component is outer than header 

// import React, { useState, useEffect } from 'react';
// import { getUser } from "../components/useAuth";

// export default function Header() {
//     const [user, setUser] = useState(null); 

//     useEffect(async () => {
//         let curUser = await getUser();
//         setUser(curUser);
//     }, [getUser]);

//     return (
//         <nav className="nav-bar">
//             <ul>
//                 <li><a href="/">Home</a></li>
//                 {user && user.id ? (
//                     <>
//                         <li><a href="/applicants">Applicants</a></li>
//                         <li><a href="/jobpost">Job Post</a></li>
//                     </>
//                 ) : null}
//             </ul>
//         </nav>
//     )
// }