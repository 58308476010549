import React, { useState } from "react"; 
import { Box, Text, Flex, Heading, SelectList } from 'gestalt';

export const Review = (
    {jobTitle, jobDescription, experienceLevel, additionalInformation, interviewQuestions, evaluationCriterion}
)  => {

    return(
        <Box display="flex" direction="column" width="100%" padding={3}>
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>  
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" /> 
                <div style={{ marginRight: '10px' }}></div> 
                <Heading>Review</Heading>
            </Flex>

            <Box display="flex" direction="column" alignItems="start" paddingY={5}>

                {[
                    { title: "Job Title", content: jobTitle===""? "Error: <Job Title cannot be empty>":jobTitle },
                    { title: "Job Description", content: jobDescription },
                    { title: "Experience Level", content: experienceLevel },
                    { title: "Additional Information", content: additionalInformation }
                ].map(((field, index) => (
                    <Box padding={3}>
                        <Text weight="bold">{field.title}:</Text>
                        <Text>{field.content}</Text>
                    </Box>
                )))
                }

                <Box padding={3}>
                    <Text weight="bold">Selected Interview Questions:</Text>
                    {interviewQuestions.length > 0 ? (
                        interviewQuestions.map((question, index) => (
                            <Text key={index}>{index + 1}. {question.content}</Text>
                        ))
                    ) : <Text>No questions selected.</Text>}
                </Box>

                <Box padding={3}>
                    <Text weight="bold">Evaluation Criterion:</Text>
                    {evaluationCriterion.length > 0 ? (
                        evaluationCriterion.map((criteria, index) => (
                            <Text key={index}>{index + 1}. {criteria}</Text>
                        ))
                    ) : <Text>No criteria selected.</Text>}
                </Box>
            </Box>
        </Box>
    )
}