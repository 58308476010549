import React, { useState, useEffect } from "react";
import axios from "axios";
import { getUser } from "../components/getUser";
import { Header } from "../components/Header";
import { Box, Flex, Heading, Button, ButtonLink } from "gestalt";
import { gapi, loadAuth2 } from "gapi-script";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
  RedirectToSignIn,
} from "@clerk/clerk-react";

const clientId = process.env.REACT_APP_CLIENT_ID;

export default function Home() {
  const [userData, setUserData] = useState({
    email: "recruiter",
    firstName: "Recruiter",
    lastName: "",
    id: null,
  });

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { isSignedIn, user, isLoaded } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoaded) {
        let userInfo = await getUser(isSignedIn, user);
        setUserData(userInfo);
        if (userInfo["email"] != "recruiter") {
          setIsAuthenticated(true);
        }
      }
    };
    fetchUserData();
  }, [isSignedIn, user, isLoaded]); // Empty dependency array means this effect runs once after the initial render

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
      <Box
        alignItems="center"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        padding={8}
        marginTop={12}
      >
        <Flex
          direction="column"
          gap={6}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            alignItems="center"
            height="100%"
            justifyContent="center"
            padding={8}
          >
            <Heading size="600">Hi {userData["firstName"]}! </Heading>
          </Box>
          <Box
            alignItems="center"
            height="100%"
            justifyContent="center"
            padding={8}
          >
            <Box>
              <Flex
                direction="column"
                gap={4}
                alignItems="center"
                justifyContent="center"
              >
                <Box width={200}>
                  <ButtonLink
                    color="gray"
                    disabled={!isAuthenticated}
                    href="/jobpost"
                    size="md"
                    text="Post a Job"
                    fullWidth
                  />
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
}
