import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";
import { gapi, loadAuth2 } from "gapi-script";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

// export async function getUser()  {
//     let auth2 = await loadAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '')
//     if (auth2.isSignedIn.get()) {
//         let user = auth2.currentUser.get()

//         let userData = {
//             email: user.getBasicProfile().getEmail(),
//             firstName: user.getBasicProfile().getGivenName(),
//             lastName: user.getBasicProfile().getFamilyName(),
//             id: null,
//         };
//         console.log('Sign in with Email: ' +user.getBasicProfile().getEmail())
//         const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
//         try {
//             let response = await axios.post(
//                 apiUrl, {
//                 email: user.getBasicProfile().getEmail(),
//                 firstName: user.getBasicProfile().getGivenName(),
//                 lastName: user.getBasicProfile().getFamilyName(),
//                 }
//             )
//             userData.id = response.data.data.recruiterID;
//             return userData;
//         } catch(error) {
//             console.error("Error saving message:", error);
//         }
//     }
//     let defaultUser = {
//         email: "recruiter",
//         firstName: "Recruiter",
//         lastName: "",
//         id: null,
//       };
//     return defaultUser;
// }

export const getUser = async () => {
  // const { isSignedIn, user, isLoaded } = useUser();
  // if (isLoaded&isSignedIn&&user!==undefined){
  //     let userData = {
  //         email: user.primaryEmailAddress,
  //         firstName: user.firstName,
  //         lastName: user.lastName,
  //         id: null,
  //     };
  //     console.log('Sign in with Email: ' +user.primaryEmailAddress)
  //     const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
  //     try {
  //         let response = await axios.post(
  //             apiUrl, {
  //             email: user.primaryEmailAddress,
  //             firstName: user.firstName,
  //             lastName: user.lastName,
  //             }
  //         )
  //         userData.id = response.data.data.recruiterID;
  //         return userData;
  //     } catch(error) {
  //         console.error("Error saving message:", error);
  //     }
  // }

  let defaultUser = {
    email: "recruiter",
    firstName: "Recruiter",
    lastName: "",
    id: null,
  };
  return defaultUser;
};
